@import "colors";

footer{
  background-color: $first;
  height: 200px;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  color: $dark;
  padding: 2rem;
  >div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    >h1{
      font: 700 2rem $text1;
      text-transform: uppercase;
      color: $third;
    }
    >p{
      font: 500 1.2rem $text1;

    }
    >h5{
      font: 300 2rem $text1;
      margin: 1rem 0;
      text-align: center;

    }
    >div{
      display: flex;
      flex-direction: column;
      align-items: center;
       a{
        color: $dark;
        font: 900 1rem $text2;
        &:hover{
          color: $third;
        }
       }
    }
  }
}